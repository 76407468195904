import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import blog1 from '../../assets/blog/blog1.jpeg';
import blog2 from '../../assets/blog/blog2.jpg';
import blog3 from '../../assets/blog/blog3.jpg';
import blog4 from '../../assets/blog/blog4.jpg';
import blog5 from '../../assets/blog/blog5.svg';

import './blog.css';

export default function Blog({language}) {
    return (
        <Container className="text-center anta">
            <Row className="text-center">
                {language === "RO" && ( <p className="title">&lt; Blog /&gt;</p> )}
                {language === "UK" && ( <p className="title">&lt; Blog /&gt;</p> )}
                {language === "RO" && ( <p className="subTitle" style={{ marginBottom: "40px" }}>Află mai multe lucruri despre mine, despre pasiunile mele sau despre orice alt subiect de interes</p> )}
                {language === "UK" && ( <p className="subTitle" style={{ marginBottom: "40px" }}>Learn more about me, my passions or any other topic of interest</p> )}
            </Row>
            <Row>
                <Col lg={4}>
                    <Card className="blogCard">
                        <Card.Body>
                            <img src={blog5} className="cardBlogImage" alt="Blog"/>
                            <Row style={{marginBottom: "25px"}}>
                                <Col xs={6} style={{textAlign: "left"}}>
                                    <Card.Text>
                                        #5 - 03.08.2024
                                    </Card.Text>
                                </Col>
                                <Col xs={6} style={{textAlign: "right"}}>
                                    <Card.Text>
                                        {language === "RO" && <>7 minute de citit</>}
                                        {language === "UK" && <>7 minutes read</>}
                                    </Card.Text>
                                </Col>
                            </Row>
                            {language === "RO" && <Card.Title><b>Primul meu internship - FeedCheck</b></Card.Title>}
                            {language === "UK" && <Card.Title><b>My first internship - FeedCheck</b></Card.Title>}
                            <Card.Text style={{marginTop: "15px"}}>
                                {language === "RO" && <>După zeci, poate sute de aplicări, am reușit într-un final să fac rost de un internship pe timpul verii.</>}
                                {language === "UK" && <>After dozens, maybe hundreds of applications, I finally managed to get an internship during the summer.</>}
                            </Card.Text>
                            <Card.Text>
                                {language === "RO" && <i>#internship #vara #feedcheck #web #developer</i>}
                                {language === "UK" && <i>#summer #internship #feedcheck #web #developer</i>}
                            </Card.Text>
                            {language === "RO" && <Button variant="outline-dark" href="/blog/primul-meu-internship-feedcheck">Citește mai mult</Button> }
                            {language === "UK" && <Button variant="outline-dark" href="/blog/primul-meu-internship-feedcheck">Read more</Button> }
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className="blogCard">
                        <Card.Body>
                            <img src={blog4} className="cardBlogImage" alt="Blog"/>
                            <Row style={{marginBottom: "25px"}}>
                                <Col xs={6} style={{textAlign: "left"}}>
                                    <Card.Text>
                                        #4 - 27.07.2024
                                    </Card.Text>
                                </Col>
                                <Col xs={6} style={{textAlign: "right"}}>
                                    <Card.Text>
                                        {language === "RO" && <>5 minute de citit</>}
                                        {language === "UK" && <>5 minutes read</>}
                                    </Card.Text>
                                </Col>
                            </Row>
                            {language === "RO" && <Card.Title><b>Experiența mea de voluntar (asistent) de laborator</b></Card.Title>}
                            {language === "UK" && <Card.Title><b>My experience as a laboratory volunteer (assistant)</b></Card.Title>}
                            <Card.Text style={{marginTop: "15px"}}>
                                {language === "RO" && <>De când am aflat că am această posibilitate, nu am ezitat să o încerc.</>}
                                {language === "UK" && <>Ever since I found out I had this possibility, I didn't hesitate to try it.</>}
                            </Card.Text>
                            <Card.Text>
                                {language === "RO" && <i>#voluntar #asistent #pclp #pa #sda #poo #tw #ia3</i>}
                                {language === "UK" && <i>#volunteer #assistant #pclp #pa #sda #poo #tw #ia3</i>}
                            </Card.Text>
                            {language === "RO" && <Button variant="outline-dark" href="/blog/experienta-de-voluntar-asistent-de-laborator">Citește mai mult</Button> }
                            {language === "UK" && <Button variant="outline-dark" href="/blog/experienta-de-voluntar-asistent-de-laborator">Read more</Button> }
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className="blogCard">
                        <Card.Body>
                            <img src={blog3} className="cardBlogImage" alt="Blog"/>
                            <Row style={{marginBottom: "25px"}}>
                                <Col xs={6} style={{textAlign: "left"}}>
                                    <Card.Text>
                                        #3 - 20.07.2024
                                    </Card.Text>
                                </Col>
                                <Col xs={6} style={{textAlign: "right"}}>
                                    <Card.Text>
                                        {language === "RO" && <>7 minute de citit</>}
                                        {language === "UK" && <>7 minutes read</>}
                                    </Card.Text>
                                </Col>
                            </Row>
                            {language === "RO" && <Card.Title><b>De ce îmi doresc atât de mult să devin profesor?</b></Card.Title>}
                            {language === "UK" && <Card.Title><b>Why do I want to become a teacher so much?</b></Card.Title>}
                            <Card.Text style={{marginTop: "15px"}}>
                                {language === "RO" && <>Pasiunea mea pentru educație a pornit încă de când eram un copil.</>}
                                {language === "UK" && <>My passion for education started when I was a child.</>}
                            </Card.Text>
                            <Card.Text>
                                {language === "RO" && <i>#profesor #asistent #meditator #informatica #matematica</i>}
                                {language === "UK" && <i>#teacher #assistant #mentor #informatics #mathematics</i>}
                            </Card.Text>
                            {language === "RO" && <Button variant="outline-dark" href="/blog/de-ce-imi-doresc-atat-de-mult-sa-devin-profesor">Citește mai mult</Button> }
                            {language === "UK" && <Button variant="outline-dark" href="/blog/de-ce-imi-doresc-atat-de-mult-sa-devin-profesor">Read more</Button> }
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className="blogCard">
                        <Card.Body>
                            <img src={blog2} className="cardBlogImage" alt="Blog"/>
                            <Row style={{marginBottom: "25px"}}>
                                <Col xs={6} style={{textAlign: "left"}}>
                                    <Card.Text>
                                        #2 - 13.07.2024
                                    </Card.Text>
                                </Col>
                                <Col xs={6} style={{textAlign: "right"}}>
                                    <Card.Text>
                                        {language === "RO" && <>10 minute de citit</>}
                                        {language === "UK" && <>10 minutes read</>}
                                    </Card.Text>
                                </Col>
                            </Row>
                            {language === "RO" && <Card.Title><b>Experiența mea la facultate - Ingineria Sistemelor UPB (UNSTPB)</b></Card.Title>}
                            {language === "UK" && <Card.Title><b>My college experience - UPB Systems Engineering (UNSTPB)</b></Card.Title>}
                            <Card.Text style={{marginTop: "15px"}}>
                                {language === "RO" && <>Mai mereu am crezut că fac alegerile bune și știu ce fac.</>}
                                {language === "UK" && <>I always thought I was making the right choices and knew what I was doing.</>}
                            </Card.Text>
                            <Card.Text>
                                {language === "RO" && <i>#facultate #admitere #experienta #acs #ingineria #sistemlor #fmi #informatica</i>}
                                {language === "UK" && <i>#faculty #admission #experience #acs #engineering #systems #fmi #informatics</i>}
                            </Card.Text>
                            {language === "RO" && <Button variant="outline-dark" href="/blog/experienta-mea-la-facultate">Citește mai mult</Button> }
                            {language === "UK" && <Button variant="outline-dark" href="/blog/experienta-mea-la-facultate">Read more</Button> }
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className="blogCard">
                        <Card.Body>
                            <img src={blog1} className="cardBlogImage" alt="Blog"/>
                            <Row style={{marginBottom: "25px"}}>
                                <Col xs={6} style={{textAlign: "left"}}>
                                    <Card.Text>
                                        #1 - 06.07.2024
                                    </Card.Text>
                                </Col>
                                <Col xs={6} style={{textAlign: "right"}}>
                                    <Card.Text>
                                        {language === "RO" && <>3 minute de citit</>}
                                        {language === "UK" && <>3 minutes read</>}
                                    </Card.Text>
                                </Col>
                            </Row>
                            {language === "RO" && <Card.Title><b>Primul meu Blog - Ce caut de fapt aici?</b></Card.Title>}
                            {language === "UK" && <Card.Title><b>My first Blog - What am I actually looking for here?</b></Card.Title>}
                            <Card.Text style={{marginTop: "15px"}}>
                                {language === "RO" && <>Câteva lucruri despre mine, de la început până în prezent!</>}
                                {language === "UK" && <>A few things about me, from the beginning until now!</>}
                            </Card.Text>
                            <Card.Text>
                                {language === "RO" && <i>#primul #blog #despre #facultate #student #tehnologie #profesor</i>}
                                {language === "UK" && <i>#first #blog #about #faculty #student #technology #teacher</i>}
                            </Card.Text>
                            {language === "RO" && <Button variant="outline-dark" href="/blog/primul-blog-ce-caut-aici">Citește mai mult</Button> }
                            {language === "UK" && <Button variant="outline-dark" href="/blog/primul-blog-ce-caut-aici">Read more</Button> }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}