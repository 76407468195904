import BlogTemplate from "../template";

import img from '../../../assets/blog/blog2.jpg';

export default function Blog2({language}) {
    return (
        <BlogTemplate
            language={language}
            title={
                (language === "RO" && ( <p>&lt; Experiența mea la facultate - Ingineria Sistemelor UPB (UNSTPB) /&gt;</p> )) ||
                (language === "UK" && ( <p>&lt; My college experience - UPB Systems Engineering (UNSTPB) /&gt;</p> ))
            }
            image={<img src={img} className="blogImage" alt="Blog"/>}
            subTitle={
                (language === "RO" && ( <>Mai mereu am crezut că fac alegerile bune și știu ce fac.</> )) ||
                (language === "UK" && ( <>I always thought I was making the right choices and knew what I was doing.</> ))
            }
            content={
                (language === "RO" && 
                    <div>
                        <p className="blogParagraph">
                            <span class="indent">M-am</span> gândit ca astăzi să împărtășesc puțin experiența mea cu facultatea
                            și tot e înseamnă informatica pentreu mine. Ca să încep cu începutul, la final de clasa a X-a eram
                            indecis cu ce să fac în viață. Nu eram așa de hotărât precum erau alții, dar urma să iau ulterior o
                            decizie importantă în viața mea de viitor adult. Astfel că, în vara dintr-a X-a și a XI-a, m-am decis
                            către 2 domenii mari și frumoase: medicina sau informatica. Știind că în clasa a XI-a o să fac anatomie
                            la liceu, am zis că atunci o să-mi dau seama dacă o să-mi placă sau nu, iar dacă nu, voi fi ales
                            informatica. Îmi aduc aminte că îmi printasem tot manualul de admitere de pe vremea aceea și făcusem un
                            portofoliu :). Ce amintiri...
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Am</span> ajuns în clasa a XI-a, însă anatomia nu mi-a plăcut, așa că m-am legat
                            de informatică. Îmi plăcea foarte mult. Începusem să fac matrici, șiruri de caracterer și altele
                            asemenea (eu am studiat liceul la un profil de matematică-informatică neintensiv, deci până în clasa a
                            X-a am făcut maxim vectori). Și deci, de aici și pasiunea mea pentru informatică. Am început să mă
                            informez încetul cu încetul de facultăți. Astfel că, la finalul clasei a XI-a eram așa de informat,
                            încât cu ușurință aș fi putut susține toate procedurile de admitere chiar în anul acela.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Pentru</span> mine era clar: vreau să studiez informatica. Găsisem informații
                            despre UPB ACS, UNIBUC FMI, ASE CSIE și chiar și UTCB, Facultatea de Hidrotehnică, acolo unde se
                            află o secție de Automatică și Informatică Aplicată (Un fel de UPB ACS IS, mai low level). Ce nu
                            știam eu bine pe atunci este că IS (Ingineria Sistemelor) nu era ceea ce pare să fie.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Zis</span> și făcut. Am ajuns a XII-a, am terminat cu bacul și am dat să mă
                            înscriu la facultăți, la toate 4. Am reușit să fiu admis pe bază de dosar la ASE la Cibernetică
                            Economică și la UTCB la Automatică și Informatică Aplicată, iar ulterior au urmat cele 2 admiteri
                            scrise, mai întâi la UNIBUC FMI, ulterior la UPB ACS. La FMI nu fusesem acceptat inițial, însă eram
                            al 18-lea în așteptare la domeniul Informatică cu media 8.18 sau ceva de genul (adică eram 100%
                            acceptat după primele confirmări, iar asta s-a dovedit după ce media finală a scăzut undeva pe la 7
                            și ceva). Cu toate astea, la UPB ACS altfel au stat lucrurile. Am dat admiterea și am greșit 3 grile
                            la matematică (nu era punctul meu forte) și la informatică una (și acum îmi aduc aminte, fusese
                            singura grilă pe care nu am verificat-o de cel puțin 2 ori).
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Astfel</span> că, după terminarea admiterii la UPB ACS, ieșind din sala de
                            admitere am aflat că eram în așteptare la FMI. Eram demoralizat cu ACS, chiar redeam că nu am șanse
                            de intrare, având în total 4 grile greșite și media de adtmiere 8.50 Eram convis că la specializarea
                            Calculatoare și Tehnologia Informației nu am nicio șansă, dar mai speram totuși la Ingineria
                            Sistemelor. Cu toate astea, la 1/2 zile am dat refresh pe site-ul admiterii la ACS și am aflat că am
                            fost admis la Ingineria Sistemelor. Am sărit în sus de bucurie și abia atunci a început adevărata mea
                            întrebare. Ce să aleg? Repet: la UPB ACS IS eram acceptat, iar la UNIBUC FMI Informatică eram al 18-lea
                            în așteptare, cu șanse 100% de intrare. Atunci m-am uitat din nou la planurile de învățământ și am
                            citit ce se face, cum se face și cât se face. Știam un singur lucru: vreau informatică pură.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Iar</span> atunci, mi-a picat fisa: Hai să aleg Automatică, întrucât fac aceleași
                            lucruri ca la Informatică și ce contează că fac un an în plus? Măcar ies inginer. Cea mai mare greșeală
                            pe care am făcut-o. Am considerat că un an în plus nu contează și că fac aceleași lucruri, iar
                            lucrurile acestea erau total neadevărate. Pe atunci, planurile de învățământ îmi ofereau doar denumirea
                            materiilor pe care urma să le studie, fără să știu cu adevărat ce fac la ele. Majoritatea sunau bine,
                            fancy, ca și acum, dar realitatea era alta. Astfel că am ales Ingineria Sistemelor de la UPB ACS,
                            neștiind deloc în ce mă bag, în detrimentul Informaticii de la UNIBUC FMI.  
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Să</span> fiu sincer, primul an a decurs foarte bine. Nu erau așa multe diferențe
                            între CTI și IS. Lucrurile au mers bine, am terminat anul cu media 9.36, bursier, deci toate bune și
                            frumoase. Anul 2 a devenit problema, de fapt. Începea să dispară toată programarea. Pe semestrul I am
                            avut doar 2 materii de programare (Programare Orientată pe Obiecte și Tehnologii Web), iar pe semestrul
                            II am avut 0 materii de programare, pe când cei de la CTI au avut 5/5 materii de programare. Mi-am dat
                            seama abia acum, târziu că Ingineria Sistemelor nu înseamnă doar: Fac și eu un an în plus și ies
                            inginer, cât de diferit poate să fie? Ingineria Sistemelor înseamnă sisteme, matematică, eletronică
                            și multe alte materii care, pe cât de spectaculoase, pe atât de nedorite de mine. M-am străduit foarte
                            mult să duc cu bine anul acesta. Am luat totuși o restanță la o materie imposibilă pentru mine, dar
                            asta este altă poveste.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Mi-am</span> dat târziu seama că nu îmi place ce fac. Că nu suport Ingineria
                            Sistemelor și că fac o specializare care nu îmi aduce nicio satisfacție. Că învăț pe de rost niște
                            lucruri, că nu înțeleg nimic și că doar îmi doresc să trec. Tocmai și de aici mediile mele au scăzut
                            substanțial, dar ce pot să fac? Încă bursier și probabil că voi rămâne și în continuare, dar nu sunt
                            mulțumit de mine.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Dacă</span> mă întrebați de ce nu m-am mutat, o să vă spun un singur lucru:
                            orgoliu. Pur și simplu am avut orgoliu. Pentru că anul 1 a mers așa de bine, mi-am spus: Cât de
                            diferit poate să fie CTI de IS? Cât de greu poate să fie? Și m-am înșelat. Greu sau nu, nu asta era
                            problema. Problema principală era și este că, repet, nu îmi place ceea ce fac. Iar acum, va trebui
                            să mai îndur încă 2 ani de Ingineria Sistemelor, că doar nu renunț acum la facultate pentru atâta
                            lucru.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Spre</span> final, pot spune că atunci când alegi ceva ce îți va influența
                            viitorul trebuie să o faci cu cap. Sunt foarte recunoscător pentru ceea ce am și ceea ce sunt.
                            Dacă nu era Ingineria Sistemelor nu era niic UPB ACS. Automat nu era nici Liga Studenților, nici
                            zecile de prieteni pe care mi i-am făcut, sau alte lucruri care mi s-au întâmplat. Poate dacă nu
                            eram la Ingineria Sistemelor lucrurile erau mai bune, sau poate mult mai rele. Cine știe? Totuși,
                            alegeți bine când vreși ceva de la viață, ca mai apoi să nu ajungeți să regretați lucrurile și să
                            le faceți doar pentru că nu mai aveți în cotro.
                        </p>
                    </div>
                ) ||
                (language === "UK" && 
                    <div>
                        <p className="blogParagraph">
                            <span class="indent">I</span> thought that today I would share a little about my experience with university
                            and what computer science means to me. To start from the beginning, at the end of the 10th grade, I was
                            undecided about what to do in life. I wasn't as determined as others, but I was about to make an important
                            decision in my future adult life. Thus, in the summer of 10th and 11th grades, I decided between two large
                            and beautiful fields: medicine or computer science. Knowing that in the 11th grade I would take anatomy
                            in high school, I said that then I would figure out if I would like it or not, and if not, I would choose
                            computer science. I remember printing out the entire admission manual from that time and making a portfolio :). 
                            What memories...
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">I</span> reached the 11th grade, but I didn't like anatomy, so I leaned towards computer
                            science. I liked it very much. I started doing matrices, character arrays, and other such things (I studied
                            in a non-intensive math-computer science profile, so up until the 10th grade I only did vectors at most). And
                            so, that's where my passion for computer science started. I began to slowly inform myself about universities.
                            Thus, by the end of the 11th grade, I was so informed that I could easily have taken all the admission procedures
                            even that year.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">For</span> me, it was clear: I wanted to study computer science. I found information
                            about UPB ACS, UNIBUC FMI, ASE CSIE, and even UTCB, Faculty of Hydrotechnics, where there is a section of
                            Automation and Applied Informatics (a kind of UPB ACS IS, but at a lower level). What I didn't know well
                            back then was that IS (Systems Engineering) was not what it seemed to be.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Said</span> and done. I reached the 12th grade, finished the baccalaureate, and applied
                            to universities, all four of them. I managed to be admitted based on my file to ASE in Economic Cybernetics and
                            UTCB in Automation and Applied Informatics, and then followed the two written admissions, first at UNIBUC FMI,
                            then at UPB ACS. I wasn't initially accepted at FMI, but I was 18th on the waiting list for the Computer Science
                            field with an average of 8.18 or something like that (which meant I was 100% accepted after the first confirmations,
                            and this was proven when the final average dropped to around 7). However, at UPB ACS things were different. I took
                            the admission test and made three mistakes in the math section (which was not my strong point) and one in the
                            computer science section (I still remember it, it was the only question I didn't check at least twice).
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Thus</span>, after finishing the admission at UPB ACS, leaving the exam room, I found out
                            that I was on the waiting list at FMI. I was demoralized about ACS, even joking that I had no chance of getting in,
                            having a total of four wrong answers and an admission average of 8.50. I was convinced that I had no chance at the
                            Computer Science and Information Technology specialization, but I still hoped for Systems Engineering. However, one
                            or two days later, I refreshed the ACS admission site and found out that I had been admitted to Systems Engineering.
                            I jumped for joy, and that was when my real question began. What to choose? To repeat: I was accepted at UPB ACS IS,
                            and at UNIBUC FMI Computer Science I was 18th on the waiting list, with a 100% chance of getting in. Then I looked
                            again at the curriculum and read what was done, how it was done, and how much was done. I knew one thing: I wanted
                            pure computer science.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">And</span> then, it clicked: Let's choose Automation, since it covers the same things as
                            Computer Science, and what does it matter if I do an extra year? At least I'll be an engineer. The biggest mistake
                            I made. I considered that an extra year didn't matter and that I was doing the same things, which was totally untrue.
                            Back then, the curriculum only offered the names of the subjects I was going to study, without knowing what I would
                            actually do in them. Most of them sounded good, fancy, like they do now, but reality was different. Thus, I chose
                            Systems Engineering at UPB ACS, not knowing at all what I was getting into, over Computer Science at UNIBUC FMI.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">To</span> be honest, the first year went very well. There weren't many differences
                            between CTI and IS. Things went well, I finished the year with an average of 9.36, on a scholarship, so all
                            was good. The second year became the problem, actually. All the programming started to disappear. In the first
                            semester, I only had two programming subjects (Object-Oriented Programming and Web Technologies), and in the
                            second semester, I had zero programming subjects, while those in CTI had 5/5 programming subjects. I realized
                            too late that Systems Engineering doesn't just mean: I'll do an extra year and become an engineer, how different
                            can it be? Systems Engineering means systems, mathematics, electronics, and many other subjects that, as
                            spectacular as they are, were not wanted by me. I struggled a lot to get through this year well. I still took
                            a retake in a subject that was impossible for me, but that's another story.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">I</span> realized too late that I didn't like what I was doing. That I couldn't stand
                            Systems Engineering and that I was doing a specialization that didn't bring me any satisfaction. That I was
                            learning things by heart, that I didn't understand anything, and that I just wanted to pass. Hence my grades
                            dropped substantially, but what can I do? Still on a scholarship, and probably will remain so, but I'm not
                            satisfied with myself.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">If</span> you ask me why I didn't transfer, I'll tell you one thing: pride. I simply
                            had pride. Because the first year went so well, I told myself: How different can CTI be from IS? How hard can
                            it be? And I was wrong. Hard or not, that wasn't the problem. The main problem was and is that, I repeat, I
                            don't like what I'm doing. And now, I will have to endure two more years of Systems Engineering, because I won't
                            quit university just for that.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Towards</span> the end, I can say that when you choose something that will influence
                            your future, you have to do it wisely. I am very grateful for what I have and who I am. If it weren't for
                            Systems Engineering, there wouldn't be UPB ACS either. Automatically, there wouldn't be the Student League,
                            nor the dozens of friends I've made, or other things that have happened to me. Maybe if I hadn't been in Systems
                            Engineering things would have been better, or maybe much worse. Who knows? Still, choose wisely when you want
                            something from life, so you don't end up regretting things and doing them just because you have no other choice.
                        </p>
                    </div>
                )
            }
            signature={<>13.07.2024</>}
            tags={
                (language === "RO" && <>#facultate #admitere #experienta #acs #ingineria #sistemlor #fmi #informatica</>) ||
                (language === "UK" && <>#faculty #admission #experience #acs #engineering #systems #fmi #informatics</>)
            }
            previousLink={"/blog/primul-blog-ce-caut-aici"}
            previousTitle={
                (language === "RO" && <>Primul meu Blog - Ce caut de fapt aici?</>) ||
                (language === "UK" && <>My first Blog - What am I actually looking for here?</>)
            }
            nextLink={"/blog/de-ce-imi-doresc-atat-de-mult-sa-devin-profesor"}
            nextTitle={
                (language === "RO" && <>De ce îmi doresc atât de mult să devin profesor?</>) ||
                (language === "UK" && <>Why do I want to become a teacher so much?</>)
            }
        />
    )
}