import { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';

import './feedback.css';

import StarsIcon from '../../assets/icons/StarsIcon.png';

export default function Feedback({language}) {
    const [view, setView] = useState("All");

    return (
        <Container className="bg-dark principalContainer text-center anta" style={{marginTop: "25px", color: "white"}}>
            <p className="titleFeedback">&lt; Feedback /&gt;</p>
            {language === "RO" && ( <p className="subTitle">Câteva gânduri de-ale elevilor mei la finalul pregătirilor</p> )}
            {language === "UK" && ( <p className="subTitle">Some thoughts from my students at the end of the preparations</p> )}
            {language === "RO" &&
                <div className="selectProject">
                    <Button variant="light" className={view === "All" ? "selectButton" : "noSelectButton"} onClick={() => setView("All")}>Toate</Button>
                    <Button variant="light" className={view === "Informatica" ? "selectButton" : "noSelectButton"} onClick={() => setView("Informatica")}>Informatică</Button>
                    <Button variant="light" className={view === "Matematica" ? "selectButton" : "noSelectButton"} onClick={() => setView("Matematica")}>Matematică</Button>
                    <Button variant="light" className={view === "Scoala" ? "selectButton" : "noSelectButton"} onClick={() => setView("Scoala")}>Pregătire Școlară</Button>
                    <Button variant="light" className={view === "Bacalaureat" ? "selectButton" : "noSelectButton"} onClick={() => setView("Bacalaureat")}>Bacalaureat</Button>
                    <Button variant="light" className={view === "Facultate" ? "selectButton" : "noSelectButton"} onClick={() => setView("Facultate")}>Facultate</Button>
                </div>
            }
            {language === "UK" &&
                <div className="selectProject">
                    <Button variant="light" className={view === "All" ? "selectButton" : "noSelectButton"} onClick={() => setView("All")}>All</Button>
                    <Button variant="light" className={view === "Informatics" ? "selectButton" : "noSelectButton"} onClick={() => setView("Informatics")}>Informatics</Button>
                    <Button variant="light" className={view === "Mathematics" ? "selectButton" : "noSelectButton"} onClick={() => setView("Mathematics")}>Mathematics</Button>
                    <Button variant="light" className={view === "School" ? "selectButton" : "noSelectButton"} onClick={() => setView("School")}>School Preparation</Button>
                    <Button variant="light" className={view === "Baccalaureat" ? "selectButton" : "noSelectButton"} onClick={() => setView("Baccalaureat")}>Baccalaureat</Button>
                    <Button variant="light" className={view === "Faculty" ? "selectButton" : "noSelectButton"} onClick={() => setView("Faculty")}>Faculty</Button>
                </div>
            }
            <Row>
                <Carousel>
                    {(view === "All" || view === "Informatica" || view === "Bacalaureat") && language === "RO" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Ștefan 1 | Clasa a XII-a - Informatică | Bacalaureat</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Îți multumesc mult pentru ajutor, Toni. Am luat cea mai mare
                                notă din bac din clasă.
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatics" || view === "Baccalaureat") && language === "UK" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Ștefan 1 | 12th Grade - Informatics | Baccalaureate</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Thank you very much for your help, Toni. I got the highest
                                grade in the class on the baccalaureate exam.
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatica" || view === "Bacalaureat" || view === "Facultate") && language === "RO" && (
                        <Carousel.Item interval={4000}>
                            <p className="feedbackNameText">Andrada | Clasa a XII-a - Informatică | Bacalaureat și Admitere</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                După un an de munca, alături de susținerea, explicațiile și
                                dedicarea ta, pot spune că nu cred că aș fi găsit un interes mai mare sau o voința mai puternica în
                                a ajuta elevii sa-și perpetueze drumul fie oricât de tumultuos. Experienta mea a fost excelenta,
                                având mereu înțelegerea și răbdarea ta, dând dovadă de seriozitate și implicare chiar și în momentele
                                limită ce-ți puneau răbdarea, timpul, la încercare. Ajutorul tău a fost de mare folos și-ți mulțumesc
                                că te oferi să dai mai departe informația pe care o deții, pentru că explici mai bine decat mulți
                                profesori cu calificare pentru acest lucru. Mulțumesc pt colaborarea frumoasă!
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatics" || view === "Baccalaureat" || view === "Faculty") && language === "UK" && (
                        <Carousel.Item interval={4000}>
                            <p className="feedbackNameText">Andrada | 12th Grade - Informatics | Baccalaureate and Admission</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                After a year of hard work, along with your support, explanations, and dedication,
                                I can say that I couldn't have found a greater interest or stronger determination
                                to help students continue their journey no matter how tumultuous it may be. My experience
                                was excellent, always having your understanding and patience, showing seriousness and
                                commitment even in challenging moments that tested your patience and time. Your help was
                                invaluable, and I thank you for offering to share the knowledge you possess, because you
                                explain better than many qualified teachers. Thank you for the wonderful collaboration!
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatica" || view === "Scoala") && language === "RO" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Cristiana | Clasa a XI-a - Informatică | Pregătire Școlară</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Am vrut să îți zic că îți mulțumesc mult, mult pentru orele
                                făcute de pregătire, m-au ajutat foarte mult și mă bucur foarte mult că am găsit o astfel de
                                persoană, care a reușit să mă ajute să înțeleg informatica, în acest fel!
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatics" || view === "School") && language === "UK" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Cristiana | 11th Grade - Informatics | School Preparation</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                I wanted to tell you that I thank you very, very much for the preparation
                                lessons; they helped me a lot, and I am very happy to have found such a person who
                                managed to help me understand computer science this way!
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatica" || view === "Matematica" || view === "Scoala") && language === "RO" && ( 
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Mihnea 1 | Clasa a X-a - Informatică și Matematică | Pregătire Școlară</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Mulțumesc mult pentru tot ajutorul! Ai fost cu adevărat un bun
                                povățuitor, iar lucrul cu tine m-a ajutat extrem de mult la școală.
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatics" || view === "Mathematics" || view === "School") && language === "UK" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Mihnea 1 | 10th Grade - Informatics and Mathematics | School Preparation</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Thank you very much for all the help! You have truly been a great
                                guide, and working with you has greatly helped me at school.
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Matematica" || view === "Scoala") && language === "RO" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Mama lui Mihnea 2 | Clasa a V-a - Matematică | Pregătire Școlară</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Mulțumim mult, Antonio pentru tot ajutorul oferit!
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Mathematics" || view === "School") && language === "UK" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Mihnea's 2 Mom | 5th Grade - Mathematics | School Preparation</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Thank you very much, Antonio, for all the help provided!
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatica" || view === "Bacalaureat") && language === "RO" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Ștefan 2 | Clasa a XII-a - Informatică | Bacalaureat</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Am dat bacul inițial în 2022 în ambele sesiuni, dar nu am avut media necesară pentru a promova,
                                ulterior nici în iunie 2023 neavând media necesară pentru a promova. Dupa aproximativ o luna și câteva zile de muncă
                                la foc automat împreună cu Toni, am reușit împreună ca în sesiunea august 2023 să ajung de la nota
                                6.20 la nota 8.10 la Informatica. Îți mulțumesc mult de tot pentru tot ajutorul acordat și am rămas
                                uimit de modul tău de predare și de explicare a exercițiilor!
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatics" || view === "Baccalaureat") && language === "UK" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Ștefan 2 | 12th Grade - Informatics | Baccalaureate</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                I initially took the baccalaureate exam in 2022 in both sessions but didn't have the
                                necessary grade to pass. Later, in June 2023, I still didn't have the required grade to
                                pass. After about a month and a few days of intensive work with Toni, we managed together
                                to improve my grade from 6.20 to 8.10 in Computer Science in the August 2023 session. Thank
                                you very much for all the help, and I was amazed by your teaching and explanation methods!
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Matematica" || view === "Bacalaureat") && language === "RO" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Cristina | Clasa a XII-a - Matematică | Bacalaureat</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Vreau să îți mulțumesc că mi-ai fost alături ca profesor, chiar unul dintre cei mai deschisi și
                                mai "la obiect" pe care i-am avut, ceea ce m-a ajutat foarte tare dându-mi siguranța că acum,
                                față de astă-iarnă, știu la ce să mă aștept de la examen, iar tu te-ai pliat mult ca "ceea ce știe
                                eleva mea să facă" să fie în concordanță cu ce ni s-ar putea cere, nu te-ai culcat pe o ureche cum
                                fac alți profesori care vor doar să își predea materia de multe ori. În primul rand am reparat
                                lacunele acumulate în liceu, iar apoi prin exercitiu am inceput să observam "tiparele" de probleme,
                                la matrici, la integrale etc. Plus, programul de acasă a fost foarte lejer, că dacă era să plec
                                undeva la ora 8 dimineața duminica... mi-ar fi fost mai greu într-adevăr. Mulțumesc mult de tot!
                                Ajutorul tău mi-a făcut rezultatul de la bac posibil. 
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Mathematics" || view === "Baccalaureat") && language === "UK" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Cristina | 12th Grade - Mathematics | Baccalaureate</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                I want to thank you for being with me as a teacher, even one of the most open and more "to the
                                point" that I had, which helped me a lot by giving me the assurance that now, compared to this
                                winter, I know what to expect from the exam, and you folded a lot like "what knows my student to
                                do" to be consistent with what we could be asked, you didn't sleep on one ear how do other
                                teachers who just want to teach their subject many times. First of all I fixed it the gaps
                                accumulated in high school, and then through the exercise we started to notice the "patterns" of
                                problems, to matrices, to integrals, etc. Plus, the schedule at home was very relaxed, that if I
                                had to leave somewhere around 8am on sunday... it would have been harder for me really. Thank you
                                so much for everything! Your help made my baccalaureate result possible.
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Matematica" || view === "Bacalaureat") && language === "RO" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Luca | Clasa a XII-a - Matematică | Bacalaureat</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Deci îți mulțumesc enorm de mult pentru ajutorul pe care mi l-ai oferit. Este clar și meritul
                                tău nota mea din bac, fără tine nu aș fi ajuns la acest rezultat.
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Mathematics" || view === "Baccalaureat") && language === "UK" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Luca | 12th Grade - Mathematics | Baccalaureate</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                So thank you very much for your help. The merit is also clear you my grade from the baccalaureate,
                                without you I would not have reached this result.
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatica" || view === "Facultate") && language === "RO" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Radu | Clasa a XII-a - Informatică | Admitere</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                Orele de pregătire au fost chiar relaxante, fără o presiune în a răspunde, s-au simțit de parcă
                                discutam cu un coleg în mare parte. Au fost de mare folos la împărtășirea diferitelor metode în
                                rezolvarea problemelor. Au ajutat la înțelegerea și deslușirea problemelor. Recomand cu căldură!
                            </p>
                        </Carousel.Item>
                    )}
                    {(view === "All" || view === "Informatics" || view === "Faculty") && language === "UK" && (
                        <Carousel.Item interval={2500}>
                            <p className="feedbackNameText">Radu | 12th Grade - Informatics | Admission</p>
                            <img src={StarsIcon} className="stars" alt="Stars"/>
                            <p className="feedbackDescriptionText">
                                The training hours were really relaxing, no pressure to answer, they felt like I was talking with
                                a colleague mostly. They were very helpful in sharing different methods in solving problems. They
                                helped to understand and figure out the issues. Highly recommend!
                            </p>
                        </Carousel.Item>
                    )}
                </Carousel>
            </Row>
        </Container>
    )
}