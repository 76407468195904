import BlogTemplate from "../template";

import img from '../../../assets/blog/blog3.jpg';

export default function Blog3({language}) {
    return (
        <BlogTemplate
            language={language}
            title={
                (language === "RO" && ( <p>&lt; De ce îmi doresc atât de mult să devin profesor? /&gt;</p> )) ||
                (language === "UK" && ( <p>&lt; Why do I want to become a teacher so much? /&gt;</p> ))
            }
            image={<img src={img} className="blogImage" alt="Blog"/>}
            subTitle={
                (language === "RO" && ( <>Pasiunea mea pentru educație a pornit încă de când eram un copil.</> )) ||
                (language === "UK" && ( <>My passion for education started when I was a child.</> ))
            }
            content={
                (language === "RO" && 
                    <div>
                        <p className="blogParagraph">
                            <span class="indent">Context:</span> Pentru cine nu știe asta despre mine, îmi doresc extrem de mult
                            să devin profesor. De ce? Haideți să aflăm împreună de unde a pornit această pasiune.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Încă</span> de când eram mic îmi plăcea să mă joc cu sora mea (mai mică decât
                            mine cu 2 ani) “de-a școala”. Pur și simplu lucram la română și matematică, îmi plăcea să ii dau
                            teste, să le corectez și evident să-i predau. Îmi aduc aminte că la un momentdat ai mei mi-au
                            cumpărat și o tablă cu 2 părți (o parte de scris cu cretă și o parte de scris cu marker). Eram
                            foarte entuziasmat. Sau, pentru că mama mea lucra la o tipografie, mi-a adus o dată un catalog real.
                            Ce vremuri :)).
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Gânduri</span> mai serioase am avut să zic și în clasa a VIII-a la final unde mă
                            vedeam profesor de română, în niciun caz de matematică. Însă lucrurile s=-au schimbat la liceu. Prima
                            interacțiune adevărată cu “predarea” am avut-o la final de clasa a X-a când am ajutat o fată să se
                            transfere în același liceu cu mine de la specializarea filologie la specializarea la care eram și eu,
                            anume matematică-informatică. Am ajutat-o la informatică atunci, ceva chestii simple în pseudocod
                            (materie de clasa a IX-a) și puțin C++ cu maxim capitolul de vectori (materie de clasa a X-a).
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Abia</span> atunci am decis (făcând o referire la ce am zis în blog-ul anterior)
                            că vreau să urmez o cerieră în domeniul informaticii. Și tot atunci am decis cu adevărat că vreau să
                            devin profesor de informatică. Mi-a plăcut să ajut, să îndruum, iar când cineva îmi spunea că înțelege
                            de la mine, mă simțeam cel mai împlinit.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Prima</span> experiență a fost la final de clasa a XI-a când (nu mai țin minte
                            cum) am ajuns să postez pe un grup de meditații sau ceva de genul un anunț în care spuneam că ofer
                            pregătire gratis pe Google Classroom (la modă pe vremea aceea datorită Covidului) și deci așa s-au
                            adunat în acel Classroom 89 de persoane (fix atâți au fost că și acum mai am cursul la arhivate). Am
                            predat niște lucruri simple de clasa a IX-a, X-a, precum algoritmi elementari, pseudocod. Sincer să
                            fiu nu știu ce a fost în capul meu atunci, căci dacă stau să mă gândesc mai bine m-am cam aruncat în
                            cap, dar a mers ok și nu am primit feedback negativ.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Următoarea</span> experiență a fost în clasa a XII-a când am început să dau
                            meditații. Chiar nu mai țin minte cum (probabil tot prin intermediul acestui curs), dar am ajuns să
                            ofer pregătire unui băiat (Ștefan) la informatică. El era tot clasa a XII-a ca mine, deci am mers
                            amândoi în paralel cu materia, cu toate că eu eram evident mult mai avansat decât el. Ștefan a fost
                            foarte mulțumit de mine, a luat cea mai mare notă din liceu, undeva pe la 8 și ceva. Cu toate că
                            nota pare mică, el era extrem de leneș, astfel că, fără să-și facă temele absolut deloc și cu efort
                            minum, tot a reușit să ia nota asta care deci se înțelege acum de ce este foarte mare.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Anii</span> au trecut. După Ștefan am prins încredere și am început să am mai
                            mulți copii la pregătire. Am avut mulți. Atât “pe bani”, cât și gratis prin diferite sesiuni pe care
                            le mai organizam sau cine știe cum au ajuns al mine.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">După</span> intrarea la facultate, am început să fac și modulul psihopedagogic.
                            Am început să înțeleg mai bine cums tau lucrurile și am observat că există posibilitatea ca pe viitor
                            să devin profesor universitar. În anul 1 nu știam prea multe, însă am încercat să mă informez și am
                            ajuns să aflu multe lucruri care ulterior aveau să mă ajute pe viitor.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">În</span> anul 2 deja am devenit voluntar în cadrul laboratoarelor (un fel de
                            asistent universitar neplătit). Pe semestrul I am avut laboratoare de Programarea Calculatoarelor.
                            Îmi plăcea așa de mult ce făceam, încât la un momentat ajunsesem să stau 8 ore la laboratoarele
                            acestea, profitând de ziua mea aproape liberă de luni. Pe semestrul II am avut Structuri de Date și
                            Algoritmi. Aici am lăsat-o mai moale, dar tot m-am implicat așa cum am putut și mi-am dorit.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Continui</span> să mă dezvolt, astfel că la anul (începând din octombrie când o
                            să fiu anul 3) voi continua cu cele 2 materii la care se adaugă pe semestrul I Programare Orientată
                            pe Obiect și Tehnologii Web, iar pe semestrul II Informatică Aplicată (Managementul Documentelor).
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Îmi</span> place foarte mult ceea ce fac. Mă văd predând la catedră, la centre
                            de meditații, individual și oriunde se poate face școală. Îmi doresc ca pe viitor să ocup un post
                            într-un liceu, ulterior și în universitate drept asistent universitar (legal), iar pe viitor cine
                            știe?
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">A</span> da, am uitat să precizez: Eu am predat principal informatică, dar am
                            avut și copii la matematică. Astfel că, mai nou îmi doresc să obțin și dreptul de a preda matematică,
                            așa că pe viitor îmi doresc să fac un Master Didactic în Matematică pentru a obține acest drept.
                        </p>
                    </div>
                ) ||
                (language === "UK" && 
                    <div>
                        <p className="blogParagraph">
                            <span class="indent">Context:</span> For those who don't know this about me, I have a strong desire
                            to become a teacher. Why? Let's find out together where this passion started.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Ever since</span> I was young, I enjoyed playing "school" with my sister (who
                            is 2 years younger than me). I simply worked on Romanian and math, liked giving her tests, correcting
                            them, and, of course, teaching her. I remember that at one point, my parents bought me a two-sided
                            board (one side for chalk writing and the other for marker writing). I was very excited. Or, because
                            my mother worked at a printing house, she once brought me a real catalog. What times :)).
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">More serious</span> thoughts came to me in the 8th grade, where I saw myself as
                            a Romanian teacher, definitely not a math teacher. But things changed in high school. The first real
                            interaction with "teaching" came at the end of 10th grade when I helped a girl transfer to my high
                            school from a philology specialization to the one I was in, namely math-computer science. I helped
                            her with computer science then, some simple things in pseudocode (9th-grade subject) and a bit of C++
                            up to the chapter on vectors (10th-grade subject).
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">It was only</span> then that I decided (referring to what I said in the previous
                            blog) that I wanted to pursue a career in computer science. And it was then that I truly decided that
                            I wanted to become a computer science teacher. I enjoyed helping, guiding, and when someone told me
                            they understood from me, I felt the most fulfilled.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">The first</span> experience was at the end of the 11th grade when (I don't
                            remember how) I posted an ad in a tutoring group or something like that, saying I was offering free
                            tutoring on Google Classroom (popular at that time due to Covid), and thus 89 people joined that
                            Classroom (exactly that many, as I still have the course archived). I taught some simple 9th and
                            10th-grade things, like elementary algorithms, pseudocode. Honestly, I don't know what was in my head
                            back then, because looking back, I kind of jumped in headfirst, but it went okay and I didn't receive
                            negative feedback.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">The next</span> experience was in the 12th grade when I started giving private
                            lessons. I really don't remember how (probably through that course), but I ended up tutoring a boy
                            (Ștefan) in computer science. He was also in the 12th grade like me, so we went through the material
                            in parallel, even though I was obviously much more advanced than him. Ștefan was very pleased with me;
                            he got the highest grade in the school, somewhere around 8 and something. Although the grade seems
                            low, he was extremely lazy, so without doing his homework at all and with minimal effort, he still
                            managed to get that grade, which now can be understood why it is very high.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Years</span> passed. After Ștefan, I gained confidence and started having more
                            students. I had many. Both "paid" and free through various sessions I organized or however they 
                            with me.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">After</span> entering university, I started the psychopedagogical module. I began
                            to understand better how things work and noticed that there is a possibility that in the future I
                            could become a university professor. In the first year, I didn't know much, but I tried to inform
                            myself and found out many things that would later help me in the future.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">In</span> the second year, I already became a volunteer in laboratories (a kind
                            of unpaid teaching assistant). In the first semester, I had Computer Programming laboratories. I liked
                            what I was doing so much that at one point I was spending 8 hours in these labs, taking advantage of
                            my almost free Monday. In the second semester, I had Data Structures and Algorithms. Here I took it
                            easier, but I still got involved as much as I could and wanted to.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">I continue</span> to develop, so next year (starting in October when I will be in
                            the 3rd year) I will continue with the 2 subjects, adding in the first semester Object-Oriented
                            Programming and Web Technologies, and in the second semester Applied Computer Science (Document
                            Management).
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">I</span> really like what I do. I see myself teaching at a desk, in tutoring
                            centers, individually, and anywhere education can happen. In the future, I hope to hold a position in
                            a high school, and later in a university as a legal teaching assistant, and who knows what the future
                            holds?
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Oh</span> yes, I forgot to mention: I mainly taught computer science, but I also
                            had students for math. So, more recently, I want to obtain the right to teach math as well, so in the
                            future, I plan to pursue a Master's Degree in Mathematics Education to obtain this right.
                        </p>
                    </div>
                )
            }
            signature={<>20.07.2024</>}
            tags={
                (language === "RO" && <>#profesor #asistent #meditator #informatica #matematica</>) ||
                (language === "UK" && <>#teacher #assistant #mentor #informatics #mathematics</>)
            }
            previousLink={"/blog/experienta-mea-la-facultate"}
            previousTitle={
                (language === "RO" && <>Experiența mea la facultate - Ingineria Sistemelor UPB (UNSTPB)</>) ||
                (language === "UK" && <>My college experience - UPB Systems Engineering (UNSTPB)</>)
            }
            nextLink={"/blog/experienta-de-voluntar-asistent-de-laborator"}
            nextTitle={
                (language === "RO" && <>Experiența mea de voluntar (asistent) de laborator</>) ||
                (language === "UK" && <>My experience as a laboratory volunteer (assistant)</>)
            }
        />
    )
}