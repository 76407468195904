import BlogTemplate from "../template";

import img from '../../../assets/blog/blog4.jpg';

export default function Blog4({language}) {
    return (
        <BlogTemplate
            language={language}
            title={
                (language === "RO" && ( <p>&lt; Experiența mea de voluntar (asistent) de laborator /&gt;</p> )) ||
                (language === "UK" && ( <p>&lt; My experience as a laboratory volunteer (assistant) /&gt;</p> ))
            }
            image={<img src={img} className="blogImage" alt="Blog"/>}
            subTitle={
                (language === "RO" && ( <>De când am aflat că am această posibilitate, nu am ezitat să o încerc.</> )) ||
                (language === "UK" && ( <>Ever since I found out I had this possibility, I didn't hesitate to try it.</> ))
            }
            content={
                (language === "RO" && 
                    <div>
                        <p className="blogParagraph">
                            <span class="indent">Ce</span> nu mulți știu despre Facultatea de Automatică și Calculatoare este
                            faptul că poți să devi voluntar (asistent) de laborator în cadrul mai multor laboratoare. Spun
                            (asistent) pentru că nu avem gradul propriu-zis de asistent, dar suntem ca niște asistenți.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Ce</span> face un voluntar (asistent)? Ei bine, acesta este mâna dreapta a
                            profesorului laborant și îl ajuta pe întregul laborator să desfășoare activitatea alături de studenți.
                            Acesta îi ajută pe studenți la efectuarea exerciților, problemelor sau efectiv a lucrurilor pe care
                            trebuie să le facă la laborator și le este suport permanent pe toată durata laboratorului.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Când</span> am aflat că am această posibilitate de a deveni voluntar (asistent)
                            nu am ezitat, așa că anul următor am luat legatură cu profesorul din cadrul materiei la care voiam să
                            fac acest voluntariat și iată că am și reușit. Primele mele voluntariate au fost la materiile de
                            Programarea Calculatoarelor și Limbaje de Programare pe semestrul I al anului 1 și Proiectarea
                            Algoritmilor (Structuri de Date și Algoritmi) pe semestrul II al aceluiași an.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Pe</span> semestrul I deci am avut PCLP și pot spune că mi s-a părut fascinant.
                            Prinsesem și un orar bun cu un singur laborator luni și până s-au așezat lucrurile, mergeam la
                            absolut 4/4 laboratoare, echivalentul a 8 ore de lucru continuu cu studenții, însă acest lucru îmi
                            aducea extrem de multă satisfacție și împlinire sufletească. Îi ajutam cu programarea în C, lucruri
                            simple pentru mine și ușor de explicat, mai ales experiența anterioară pe care deja o aveam. Îmi
                            plăcea să ajut, iar faptul că lumea răspundea pozitiv ajutorului meu, asta m-a motivat și mai mult.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Pe</span> semestrul II la PA lucrurile s-au mai liniștit. Am avut doar 1
                            interval, echivalentul a 2 ore, întrucât aici orarul nu era unul așa bun și pe lângă asta eram și
                            mai multi voluntari. Aici am ajutat și la organizarea temei, sfaturi și alte lucruri generale /
                            administrative pe acest subiect.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Lucrurile</span> au evoluat, iar deci acum urmează să ajung în anul 3. Astfel
                            că, pe anul anterior (anul 2) am mai avut materii care mi-au plăcut și urmează ca, pe lângă cele 2
                            la care eram deja înscris, să mai am încă alte 3, anume: Programare Orientată pe Obiect, Tehnologii
                            Web și Informatică Aplicată 3.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Tot</span> ce pot spune este că îmi place ceea ce fac. Mă văd devenind profesor
                            universitar și chiar lupt pentru asta. Îmi place să ajut lumea, să explic și să văd cum lumea
                            înțelege și-mi apreciază munca. Cine știe pe viitor la ce alte materii voi mai deveni voluntar...
                        </p>
                    </div>
                ) ||
                (language === "UK" && 
                    <div>
                        <p className="blogParagraph">
                            <span class="indent">What</span> many people don't know about the Faculty of Automation and Computersis
                            that you can become a volunteer (assistant) in various laboratories. I say (assistant) because we don't have the actual title of assistant, but we function just like assistants.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">What</span> does a volunteer (assistant) do? Well, they are the right-hand of the lab instructor and help the entire lab run smoothly alongside the students.
                            They assist students with exercises, problems, or whatever tasks they need to complete in the lab and provide constant support throughout the lab session.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">When</span> I found out about the opportunity to become a volunteer (assistant), I didn't hesitate, so the next year I got in touch with the professor of the subject I wanted to volunteer for, and I succeeded. My first volunteer experiences were with the subjects Computer Programming and Programming Languages in the first semester of the first year and Algorithm Design (Data Structures and Algorithms) in the second semester of the same year.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">In</span> the first semester, I had CPPL, and I found it fascinating.
                            I had a good schedule with only one lab on Mondays, and until things settled, I attended all 4/4 labs, equivalent to 8 hours of continuous work with students. However, this brought me great satisfaction and fulfillment. I helped with programming in C, simple things for me and easy to explain, especially given my previous experience. I enjoyed helping, and the positive response from others motivated me even more.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">In</span> the second semester with AD, things calmed down. I had only one slot, equivalent to 2 hours, as the schedule was not as good and there were more volunteers. Here, I also helped organize the assignment, provided advice, and handled other general/administrative tasks related to this subject.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Things</span> have evolved, and now I am about to enter the third year. Thus, in the previous year (the second year), I had other subjects I enjoyed, and in addition to the two I was already signed up for, I will have three more, namely: Object-Oriented Programming, Web Technologies, and Applied Informatics 3.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">All</span> I can say is that I love what I do. I see myself becoming a university professor and am even striving for it. I like helping people, explaining things, and seeing how people understand and appreciate my work. Who knows what other subjects I will volunteer for in the future...
                        </p>
                    </div>
                )
            }
            signature={<>27.07.2024</>}
            tags={
                (language === "RO" && <>#voluntar #asistent #pclp #pa #sda #poo #tw #ia3</>) ||
                (language === "UK" && <>#volunteer #assistant #pclp #pa #sda #poo #tw #ia3</>)
            }
            previousLink={"/blog/de-ce-imi-doresc-atat-de-mult-sa-devin-profesor"}
            previousTitle={
                (language === "RO" && <>De ce îmi doresc atât de mult să devin profesor?</>) ||
                (language === "UK" && <>Why do I want to become a teacher so much?</>)
            }
            nextLink={"/blog/primul-meu-internship-feedcheck"}
            nextTitle={
                (language === "RO" && <>Primul meu internship - FeedCheck</>) ||
                (language === "UK" && <>My first internship - FeedCheck</>)
            }
        />
    )
}