import BlogTemplate from "../template";

import img from '../../../assets/blog/blog5.svg';

export default function Blog5({language}) {
    return (
        <BlogTemplate
            language={language}
            title={
                (language === "RO" && ( <p>&lt; Primul meu internship - FeedCheck /&gt;</p> )) ||
                (language === "UK" && ( <p>&lt; My first internship - FeedCheck /&gt;</p> ))
            }
            image={<img src={img} className="blogImage" alt="Blog"/>}
            subTitle={
                (language === "RO" && ( <>După zeci, poate sute de aplicări, am reușit într-un final să fac rost de un internship pe timpul verii.</> )) ||
                (language === "UK" && ( <>After dozens, maybe hundreds of applications, I finally managed to get an internship during the summer.</> ))
            }
            content={
                (language === "RO" && 
                    <div>
                        <p className="blogParagraph">
                            <span class="indent">Povestea</span> începe așa: Eu, doritor de a nu sta degeaba, m-am decis să caut
                            ceva să fac și pe timpul verii, pe lângă activitățile de bază. Teoretic am fost mereu așa. Până și în
                            anul I am reușit să mă angajez tot în domeniul IT, dar acolo este cu totul o altă poveste.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Astfel</span> se face că la începutul anului II am început să lucrez la CV-ul meu.
                            Aveam ceva activități și mă consideram puțin mai avantajat decât mulți alții drept să spun, dar nu cu
                            mult. Spre exemplu, aveam Departamentul de IT, Voluntariatul, niște proiecte pe FrontEnd și faptul că
                            eram voluntar (asistent) la facultate. Decât deloc, eu zic că erau niște lucruri bune.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Zis</span> și făcut. M-am apucat deci să-mi fac CV-ul, iar ulterior să aplic la
                            tot felul de firme, orice, doar să fie. Din păcate, din toate aplicăriile mele, am avut doar 3
                            răspunsuri pozitive, în această ordine: SBP, FeedCheck și Cornerstone.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Povestea</span> la SBP a fost simplă: surprinzător sau nu, aplicasem pentru o
                            poziție de C++ (nu internship, ci junior) și am fost chemat la interviu. Primul interviu a fost cel
                            cu HR, urmând ca tot în aceeași zi să dau un test de logică. Spre rușinea mea, nu am luat punctajul
                            minim la acel test, astfel că am fost respins, iar interviul nu a mai continuat.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">La</span> Cornerstone am avut doar interviu cu HR-ul, căci între timp primisem
                            oferta de la FeedCheck care era una mult mai bună și deci am renunțaț la cei dintâi.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">La</span> FeedCheck însă povestea este mult mai interesantă. Le-am scris în
                            decembrie, însă mi-au spus că nu au posturi disponibile în aceea perioadă și să mai încerc la vară.
                            Întâmplarea face că, într-una din zilele când eram demoralizat complet că nu mă contactează nimeni,
                            primesc un mesaj de la Adrian (sau Adi), fondatorul FeedCheck care mă întreabă dacă mai sunt interesat
                            de internship. Evident că am răspuns pozitiv, nici nu am stat prea mult pe gânduri și iată că am
                            programat și primul interviu. Am început cu ceva simplu, un interviu tehnic. Nu consider că m-am
                            descurcat prea “wow”, dar apparent a fost sufficient cât să ajung aici. După acest interviu tehnic am
                            avut un interviu tip HR, tot cu Adi, iar apoi ultimul interviu pentru a primi oferta, după ce aparent
                            am trecut de primele 2 interviuri decisive. Mi-a plăcut foarte mult cum am desfășurat interviul, modul
                            lui de gândire, cu ce se ocupă firma și deci, așa am ajuns în echipă.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Acum</span> 2 zile, pe 1 august s-a făcut fix o lună de când lucrez la FeedCheck
                            și pot spune doar că-mi place foarte mult cum se defășoară lucrurile aici. Mediul este unul prietenos,
                            râdem, glumim, suntem și serioși, dar mai ales suntem o echip unită.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Pentru</span> mai multe detalii referitoare la internship, sunt angajat drept
                            “Web Development Intern” pe o perioadă determinată de 3 luni, part-time, 6 ore / zi. Lucrez la
                            aplicația principală FeedCheck în principiu pe Backend cu Django (Python), Jinja, MySQL și multe
                            altele.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Îmi</span> place ceea ce fac și consider că a meritat toată această așteptare.
                            Până la urmă contează că mi-am îndeplinit visul, iar vara mea este acum ocupată de acest internship.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Dacă</span> ar fi să dau un sfat cuiva care dorește să se angajeze și nu găsește
                            nimic nicăieri, i-aș spune că job-ul poate să vină când nici nu se așteaptă. În cazul meu așa a fost
                            și sunt ferm convins că nu sunt singurul care a trimis zeci, sau chiar sute de CV-uri până să primească
                            cu adevărat o ofertă reală.
                        </p>
                    </div>
                ) ||
                (language === "UK" && 
                    <div>
                        <p className="blogParagraph">
                            <span class="indent">The story</span> begins like this: I, eager not to stay idle, decided to find
                            something to do during the summer, in addition to the basic activities. Theoretically, I've always
                            been this way. Even in my first year, I managed to get a job in the IT field, but that's a whole
                            different story.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Thus</span> it happened that at the beginning of my second year, I started
                            working on my CV. I had some activities and considered myself a little more advantaged than many
                            others, to be honest, but not by much. For example, I had the IT Department, volunteering, some
                            FrontEnd projects, and the fact that I was a volunteer (assistant) at the university. Better than
                            nothing, I’d say those were good things.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Said</span> and done. I started working on my CV and then applying to all kinds
                            of companies, anything would do. Unfortunately, out of all my applications, I only received 3 positive
                            responses, in this order: SBP, FeedCheck, and Cornerstone.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">The story</span> at SBP was simple: surprisingly or not, I had applied for a C++
                            position (not an internship, but a junior role) and was called for an interview. The first interview was
                            with HR, followed by a logic test on the same day. To my shame, I did not achieve the minimum score on
                            that test, so I was rejected, and the interview did not continue.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">At</span> Cornerstone, I only had an interview with HR, as I had received an offer
                            from FeedCheck which was much better, so I declined the former.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">At</span> FeedCheck, however, the story is much more interesting. I had written to
                            them in December, but they told me they had no positions available at that time and to try again in the
                            summer. By chance, on one of those days when I was completely demoralized because no one was contacting me,
                            I received a message from Adrian (or Adi), the founder of FeedCheck, asking if I was still interested in
                            the internship. I obviously responded positively, didn’t think twice, and soon after, I scheduled my first
                            interview. We started with a technical interview. I don't think I did particularly "wow," but apparently,
                            it was enough to get me through. After this technical interview, I had an HR-type interview, also with Adi,
                            and then the final interview to receive the offer, after apparently passing the first two decisive interviews.
                            I really liked how the interview went, Adi's way of thinking, what the company does, and so I joined the team.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Now</span> 2 days ago, on August 1st, it marked exactly one month since I started working
                            at FeedCheck, and I can only say that I really like how things are going here. The environment is friendly, we
                            laugh, joke, are serious when needed, but above all, we are a united team.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">For</span> more details about the internship, I am employed as a “Web Development Intern”
                            for a fixed period of 3 months, part-time, 6 hours/day. I mainly work on the main FeedCheck application,
                            focusing on Backend with Django (Python), Jinja, MySQL, and many other technologies.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">I</span> enjoy what I do and believe that the wait was worth it. In the end, what matters
                            is that I fulfilled my dream, and my summer is now occupied by this internship.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">If</span> I were to give advice to someone looking for a job and not finding anything
                            anywhere, I would say that the job can come when you least expect it. In my case, that’s how it was, and I’m
                            firmly convinced that I’m not the only one who sent dozens, or even hundreds of CVs before receiving a real
                            offer.
                        </p>
                    </div>
                )
            }
            signature={<>03.08.2024</>}
            tags={
                (language === "RO" && <>#internship #vara #feedcheck #web #developer</>) ||
                (language === "UK" && <>#summer #internship #feedcheck #web #developer</>)
            }
            previousLink={"/blog/experienta-de-voluntar-asistent-de-laborator"}
            previousTitle={
                (language === "RO" && <>Experiența mea de voluntar (asistent) de laborator</>) ||
                (language === "UK" && <>My experience as a laboratory volunteer (assistant)</>)
            }
            nextLink={"none"}
            nextTitle={
                (language === "RO" && <></>) ||
                (language === "UK" && <></>)
            }
        />
    )
}