import BlogTemplate from "../template";

import img from '../../../assets/blog/blog1.jpeg';

export default function Blog1({language}) {
    return (
        <BlogTemplate
            language={language}
            title={
                (language === "RO" && ( <p>&lt; Primul meu Blog - Ce caut de fapt aici? /&gt;</p> )) ||
                (language === "UK" && ( <p>&lt; My first Blog - What am I actually looking for here? /&gt;</p> ))
            }
            image={<img src={img} className="blogImage" alt="Blog"/>}
            subTitle={
                (language === "RO" && ( <>Câteva lucruri despre mine, de la început până în prezent!</> )) ||
                (language === "UK" && ( <>A few things about me, from the beginning until now!</> ))
            }
            content={
                (language === "RO" && 
                    <div>
                        <p className="blogParagraph">
                            <span class="indent">Salutare</span> tuturor! Nu prea le am cu eu blog-urile, însă m-am decis să
                            încep și eu să scriu câte ceva și să postez aceste lucruri pe pagina mea personală.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Cine</span> sunt? Probabil dacă ai ajuns să citești asta știi ceva despre mine,
                            dacă nu, îți voi povesti acum. Numele meu este Toni, la momentul actual am 20 de ani (împlinesc 21
                            pe 14 iulie) și studiez la Facultatea de Automatică și Calculatoare. Sunt pasionat de tehnologie și
                            îmi doresc ca pe viitor să devin profesor de informatică / matematică, dar să lucrez și în IT.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Mă</span> pot mândri cu faptul că sunt o persoană serioasă, deschisă, căreia îi
                            place să ajut oamenii și să descopere diverse domenii. Sunt pasionat și de psihologie, însă momentan
                            totul este la nivel de idee. Îmi place cum sună, îmi place despre ce se vorbește, dar nu cunosc
                            îndeajuns de bine domeniul.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">De</span> asemenea, ca să răspund la întrebarea din titlu (Ce caut aici?), păi...
                            pur și simplu încerc să prezint prin intermediul website-ului câteva lucruri despre mine, proiecte
                            personale sub forma unui portofoliu, câteva informații legate de partea didactică la care activez în
                            momentul de față, iar mai nou un... BLOG. Îmi place să scriu, însă recunosc că-mi pierd repede răbdarea,
                            așa că deseori scrierile mele sunt destul de scurte.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Pe</span> final, pot spune doar că dacă dorești să mă cunoști mai bine, nu ezita
                            să-mi scrii. Mai sus, în navbar ai un buton de Contact. Îl poți apăsa și-mi poți scrie. Cine știe,
                            poate pe viitor vom putea colabora cumva.
                        </p>
                    </div>
                ) ||
                (language === "UK" && 
                    <div>
                        <p className="blogParagraph">
                            <span class="indent">Hello</span> everyone! I'm not really into blogs, but I've decided to start
                            writing and posting some things on my personal page.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Who</span> am I? If you're reading this, you probably know something about me,
                            but if not, I'll tell you now. My name is Toni, I'm currently 20 years old (turning 21 on July 14),
                            and I'm studying at the Faculty of Automation and Computers. I'm passionate about technology and I
                            aspire to become a computer science / mathematics teacher in the future, while also working in IT.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">I</span> can pride myself on being a serious, open-minded person who enjoys
                            helping people and exploring various fields. I'm also passionate about psychology, although at the
                            moment everything is at the idea level. I like how it sounds, I like what it's about, but I don't
                            know the field well enough yet.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">Also</span>, to answer the question in the title (What am I looking for here?),
                            well... I'm just trying to present a few things about myself through the website, personal projects
                            in the form of a portfolio, some information related to the educational part I'm currently involved
                            in, and lately a... BLOG. I like to write, but I admit that I quickly lose patience, so my writings
                            are often quite short.
                        </p>
                        <p className="blogParagraph">
                            <span class="indent">In</span> conclusion, I can only say that if you want to get to know me better,
                            don't hesitate to write to me. Above, in the navbar, there's a Contact button. You can click it and
                            write to me. Who knows, maybe in the future we can collaborate in some way.
                        </p>
                    </div>
                )
            }
            signature={<>06.07.2024</>}
            tags={
                (language === "RO" && <>#primul #blog #despre #facultate #student #tehnologie #profesor</>) ||
                (language === "UK" && <>#first #blog #about #faculty #student #technology #teacher</>)
            }
            previousLink={"none"}
            previousTitle={
                (language === "RO" && <></>) ||
                (language === "UK" && <></>)
            }
            nextLink={"/blog/experienta-mea-la-facultate"}
            nextTitle={
                (language === "RO" && <>Experiența mea la facultate - Ingineria Sistemelor UPB (UNSTPB)</>) ||
                (language === "UK" && <>My college experience - UPB Systems Engineering (UNSTPB)</>)
            }
        />
    )
}