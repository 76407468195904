import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";

import NavbarHeader from "./components/navbar/navbar";

import Home from "./pages/home/home";
import DespreMine from "./pages/despreMine/despreMine";
import Profesor from "./pages/profesor/profesor";
import Portofoliu from "./pages/portofoliu/portofoliu";
import Meditatii from "./pages/meditatii/meditatii";
import Blog from "./pages/blog/blog";
    import Blog1 from "./pages/blog/content/blog1";
    import Blog2 from "./pages/blog/content/blog2";
    import Blog3 from "./pages/blog/content/blog3";
    import Blog4 from "./pages/blog/content/blog4";
    import Blog5 from "./pages/blog/content/blog5";
import Contact from "./pages/contact/contact";
import Footer from './components/footer/footer';
import Error404 from "./pages/error404/error404";

import "@fontsource/open-sans";
import './App.css';

function App() {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'RO');

    const updateLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
    };

    return (
        <div className="background" style={{fontFamily: "Open Sans"}}>
            <NavbarHeader language={language} setLanguage={updateLanguage} />
            <br/>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Home language={language}/>} />
                    <Route path="/despre" element={<DespreMine language={language}/>}/>
                    <Route path="/about" element={<DespreMine language={language}/>}/>
                    <Route path="/portofoliu" element={<Portofoliu language={language}/>}/>
                    <Route path="/portofolio" element={<Portofoliu language={language}/>}/>
                    <Route path="/profesor" element={<Profesor language={language}/>}/>
                    <Route path="/teacher" element={<Profesor language={language}/>}/>
                    <Route path="/meditatii" element={<Meditatii language={language}/>}/>
                    <Route path="/blog" element={<Blog language={language}/>}/>
                        <Route path="/blog/primul-blog-ce-caut-aici" element={<Blog1 language={language}/>}/>
                        <Route path="/blog/experienta-mea-la-facultate" element={<Blog2 language={language}/>}/>
                        <Route path="/blog/de-ce-imi-doresc-atat-de-mult-sa-devin-profesor" element={<Blog3 language={language}/>}/>
                        <Route path="/blog/experienta-de-voluntar-asistent-de-laborator" element={<Blog4 language={language}/>}/>
                        <Route path="/blog/primul-meu-internship-feedcheck" element={<Blog5 language={language}/>}/>
                    <Route path="/contact" element={<Contact language={language}/>}/>
                    <Route path="*" element={<Error404 language={language}/>}/>
                </Routes>
            </Router>
            <Footer language={language}/>
        </div>
    );
}

export default App;