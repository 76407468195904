import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './blog.css';

export default function BlogTemplate({language, title, image, subTitle, content, signature, tags, previousLink, previousTitle, nextLink, nextTitle}) {
    return (
        <Container className="anta">
            <Row className="text-center">
                <p className="title">{title}</p>
                {image}
            </Row>
            <Row>
                <Col lg={8} className="order-lg-1 order-2">
                    <p className="blogSubTitle">{subTitle}</p>
                    <p className="blogContent">{content}</p>
                    <p className="blogSignature">{signature}</p>
                    <p className="blogTags">{tags}</p>
                    <Row className="text-center" style={{marginLeft: "50px", marginRight: "50px"}}>
                        <Col lg={6}>
                            {previousLink !== "none" && 
                                <a href={previousLink}>
                                    <Button variant="outline-dark" className="blogButtonLeft">
                                        {language === "RO" && <>Blogul Anterior<br/></>}
                                        {language === "UK" && <>Previous Blog<br/></>}
                                        <b>« {previousTitle}</b>
                                    </Button>
                                </a>
                            }
                        </Col>
                        <Col lg={6}>
                            {nextLink !== "none" && 
                                <a href={nextLink}>
                                    <Button variant="outline-dark" className="blogButtonRight">
                                        {language === "RO" && <>Blogul Următor<br/></>}
                                        {language === "UK" && <>Next Blog<br/></>}
                                        <b>{nextTitle} »</b>
                                    </Button>
                                </a>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col lg={3} className="text-center order-lg-2 order-1">
                    {language === "RO" && <p className="blogSubTitle">Cele mai populare...</p>}
                    {language === "UK" && <p className="blogSubTitle">The most popular...</p>}
                    <div className="d-flex flex-column align-items-center popularCard">
                        <a href="/blog/primul-blog-ce-caut-aici">
                            <Button variant="outline-dark" className="blogCard">
                                {language === "RO" && <b>Primul meu Blog - Ce caut de fapt aici?</b>}
                                {language === "UK" && <b>My first Blog - What am I actually looking for here?</b>}
                            </Button>
                        </a>
                        <a href="/blog/experienta-mea-la-facultate">
                            <Button variant="outline-dark" className="blogCard">
                                {language === "RO" && <b>Experiența mea la facultate - Ingineria Sistemelor UPB (UNSTPB)</b>}
                                {language === "UK" && <b>My college experience - UPB Systems Engineering (UNSTPB)</b>}
                            </Button>
                        </a>
                        <a href="/blog/de-ce-imi-doresc-atat-de-mult-sa-devin-profesor">
                            <Button variant="outline-dark" className="blogCard">
                                {language === "RO" && <b>De ce îmi doresc atât de mult să devin profesor?</b>}
                                {language === "UK" && <b>Why do I want to become a teacher so much?</b>}
                            </Button>
                        </a>
                        <a href="/blog/experienta-de-voluntar-asistent-de-laborator">
                            <Button variant="outline-dark" className="blogCard">
                                {language === "RO" && <b>Experiența mea de voluntar (asistent) de laborator</b>}
                                {language === "UK" && <b>My experience as a laboratory volunteer (assistant)</b>}
                            </Button>
                        </a>
                        <a href="/blog/primul-meu-internship-feedcheck">
                            <Button variant="outline-dark" className="blogCard">
                                {language === "RO" && <b>Primul meu internship - FeedCheck</b>}
                                {language === "UK" && <b>My first internship - FeedCheck</b>}
                            </Button>
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}